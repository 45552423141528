.el-checkbox {
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: theme-color('primary');
    border-color: theme-color('primary');
  }

  .el-checkbox__input .el-checkbox__inner {
    width: 16px;
    height: 16px;
    font-size: 16px;
    border-radius: 3px;

    &::before {
      top: 6px;
      border-color: $custom-control-indicator-checked-border-color;
    }
    &::after {
      height: 9px;
      left: 5px;
    }
    &:hover {
      border-color: theme-color('primary');
    }
  }
}
