//
// Vector Map
//


.vector-map {
    position: relative;
    height: 600px;
}


// Size variations

.vector-map-sm {
    height: 180px;
}
