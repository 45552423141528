/*LOGIN STYLE*/
html, body {
  height: 100%;
}
.main-page {
  width: 100%;
  height: 100%;
}
.login-page {
    /*background: linear-gradient(to bottom right, #2f1e56, #962dff);*/
    background: url("/assets/smartcat/bg_login.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100%;
}

/*.card {
    background-image: url("../../images/background_login.png");
}*/

.btn-login {
  background: linear-gradient(to left, #2e41db, #5626c7);
  padding-right: 40px; 
  padding-left: 40px; 
  color: #fff;
  outline-color: black;
}

.style-input {
  padding-right: 40px; 
  padding-left: 40px;
}

.page-loading {
  height: 300px;
  line-height: 300px;
  text-align: center;
}

.border-radius-20 {
  border-radius: 20px;
}

.scroll-notifications{
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* UNTUK TD TABLE UKURAN KECIL */
.customTableInputB4 tbody tr td {
  padding: 0px !important;
}

/* UNTUK FIXED HEADER */
.header-fixed{
  overflow: scroll;
}

.header-fixed thead tr:nth-child(1) th{
  position: sticky;
  top: 0;
  z-index: 10;
}


.header-fixed-multiple thead{
  position: sticky;
  top: 0;
}
.focusedInput:focus {
  border: 2px solid #ffe391 !important
}

.custom-margin-top-25px.el-select .el-input .el-input__inner{
  margin-top:25px;
}

/* Style digunakan untuk mengatasi saat terdapat pop up lagi swall akan tampil paling depan */
.swal2-container {
  display: -webkit-box;
  display: flex;
  position: fixed;
  z-index: 300000;
}

